import '@/styles/globals.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;
import '@pqina/pintura/pintura.css';
import 'react-tooltip/dist/react-tooltip.css';
import '@pickleballinc/react-ui/stylesheets/bundle.css';
import 'react-toastify/dist/ReactToastify.css';

import { Footer } from '@pickleballinc/ui/components/pickleball-app/Footer';
import ScrollToTop from '@pickleballinc/ui/components/pickleball-app/ScrollToTop';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import Head from 'next/head';
import { ReactElement, ReactNode } from 'react';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import ConnectDUPRModalWrappperPages from '@/components/ConnectDUPRModal/ConnectDUPRModalWrapperPages';
import HeaderV2 from '@/components/Header/HeaderV2';
import { PreHeader } from '@/components/Header/PreHeader';
import ImpersonationWrapperPages from '@/components/ImpersonationWrapper/ImpersonationWrapperPages';
import { RenderScripts } from '@/components/RenderScripts';
import { ImpressionProviderPages } from '@/contexts/impressions/ImpressionProviderPages';
import { LoaderProvider } from '@/contexts/leagues/LoaderProvider';
import { SessionAuthProvider } from '@/contexts/sessionAuth/SessionAuthProvider';
import getCDNURL from '@/utils/helpers/getCDNURL';

// Load Inter font
const inter = Inter({ subsets: ['latin'] });

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

export default function MyApp({
	Component,
	pageProps: { session, isTourneyPage, isLeaguePage, hideHeaderAndFooter, isImpersonationInProcess, ...pageProps }
}: AppPropsWithLayout) {
	const queryClient = React.useMemo(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						refetchOnWindowFocus: false
					}
				}
			}),
		[]
	);
	// Use the layout defined at the page level, if available
	const getLayout = Component.getLayout ?? ((page) => page);
	return (
		<>
			<div id="root" className={`antialiased ${inter.className}`}>
				<LoaderProvider>
					<SessionAuthProvider session={session}>
						<Head>
							<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
							<meta name="theme-color" content="#141c2e" />
							<link
								rel="icon"
								href={`${getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/${isTourneyPage ? 'pickleballtournaments-app' : 'pickleball-app'}/favicon-32x32.png`, 32, 32)}`}
							/>
						</Head>
						<ImpressionProviderPages session={session}>
							<QueryClientProvider client={queryClient}>
								<Hydrate state={pageProps.dehydratedState}>
									<ImpersonationWrapperPages session={session} isImpersonationInProcess={isImpersonationInProcess} />
									<div className={`main-app bg-gray-50`}>
										{!hideHeaderAndFooter && (
											<>
												<PreHeader
													isTourneyPage={isTourneyPage}
													applyWhiteBg={pageProps.player || pageProps.resultsMatchPage}
												/>
												<HeaderV2 isTourneyPage={isTourneyPage} isLeaguePage={isLeaguePage} />
											</>
										)}
										<ToastContainer theme="light" position="top-center" />
										<div>{getLayout(<Component {...pageProps} />)}</div>
										{process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
										{!hideHeaderAndFooter && (
											<Footer
												isTourneyPage={isTourneyPage}
												baseUrl={`${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}`}
												cdnUrl={`${process.env.NEXT_PUBLIC_IMAGE_CDN}`}
											/>
										)}
									</div>

									{process.env.NEXT_PUBLIC_APP_ENV === 'prod' && <ConnectDUPRModalWrappperPages session={session} />}
								</Hydrate>
							</QueryClientProvider>
						</ImpressionProviderPages>
					</SessionAuthProvider>
				</LoaderProvider>
				<ScrollToTop />
			</div>
			<div id="callback-box" />
			<Tooltip
				id="tooltip"
				style={{
					fontSize: 12,
					padding: '3px 6px',
					borderRadius: 2,
					background: 'rgba(0, 0, 0.75)'
				}}
			/>
			{isTourneyPage && process.env.NEXT_PUBLIC_APP_ENV === 'prod' && <RenderScripts ga_id={process.env.NEXT_PUBLIC_PT_GOOGLE_ANALYTICS} />}
			{!isTourneyPage && process.env.NEXT_PUBLIC_APP_ENV === 'prod' && <RenderScripts ga_id={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS} />}
		</>
	);
}
