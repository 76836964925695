import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';

import ConnectDUPRModal from '@/components/ConnectDUPRModal/ConnectDUPRModal';
import { UserSession } from '@/contexts/sessionAuth/SessionAuthProvider';
import { GetDuprPlayerDataRes } from '@/utils/actions/players/edit/getDuprPlayerData';
import { hasPermission } from '@/utils/helpers/hasPermission';

interface ConnectDUPRModalWrappperPagesProps {
	session?: UserSession;
}

const ConnectDUPRModalWrappperPages = ({ session }: ConnectDUPRModalWrappperPagesProps) => {
	const isSuperAdmin = hasPermission(['SUPER_ADMIN'], session?.session.roles);
	const { data: duprData } = useQuery(
		['dupr-player-data'],
		async () => {
			const { data } = await axios.get<{ data: GetDuprPlayerDataRes }>(`/api/v2/duprData?slug=${session?.session?.uuid}`);
			return data.data;
		},
		{
			refetchOnWindowFocus: false,
			enabled: session?.session?.uuid && !isSuperAdmin ? true : false
		}
	);

	if (!session?.session?.uuid || isSuperAdmin || !duprData) {
		return null;
	}

	return <ConnectDUPRModal duprData={duprData} isLoggedIn />;
};

export default ConnectDUPRModalWrappperPages;
