import React from 'react';

import { UserSession } from '@/contexts/sessionAuth/SessionAuthProvider';

import ImpersonationBar from '../ImpersonationBar/ImpersonationBar';

interface ImpersonationWrapperPagesProps {
	session?: UserSession;
	isImpersonationInProcess?: boolean;
}

const ImpersonationWrapperPages = ({ session, isImpersonationInProcess }: ImpersonationWrapperPagesProps) => {
	if (!isImpersonationInProcess || !session?.session || !session?.data) {
		return null;
	}

	return (
		<ImpersonationBar
			{...{
				firstName: session.data.firstName,
				lastName: session.data.lastName,
				email: session.session.email,
				uuid: session.session.uuid,
				pbUuid: session.session.pbUuid
			}}
		/>
	);
};

export default ImpersonationWrapperPages;
