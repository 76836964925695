import EverflowScript from '@/components/RenderScripts/EverflowScript';
import { FacebookBodyScript } from '@/components/RenderScripts/FacebookScript';
import RenderGoogleScripts from '@/components/RenderScripts/RenderGoogleScripts';

interface RenderScriptsProps {
	ga_id?: string;
}

const RenderScripts = ({ ga_id }: RenderScriptsProps) => (
	<>
		{ga_id && <RenderGoogleScripts ga_id={ga_id} />}
		<EverflowScript />
		<FacebookBodyScript />
	</>
);

export default RenderScripts;
