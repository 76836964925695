import Script from 'next/script';

const FacebookHeadScript = () => {
	return (
		<Script id="fb-pixel" strategy="afterInteractive">
			{`!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
				n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
				document,'script','https://connect.facebook.net/en_US/fbevents.js?v=next');
				var url = window.location.origin + '?ob=open-bridge';
				fbq('set', 'openbridge', '1780685049004027', url);
				fbq('init', '1780685049004027', {}, {"agent": "wordpress-6.6.2-3.0.16"});
				fbq('track', 'PageView');
			`}
		</Script>
	);
};

const FacebookBodyScript = () => {
	return (
		<noscript>
			<img
				height="1"
				width="1"
				style={{ display: 'none' }}
				alt="fbpx"
				src="https://www.facebook.com/tr?id=1780685049004027&ev=PageView&noscript=1"
			/>
		</noscript>
	);
};

export { FacebookBodyScript, FacebookHeadScript };
