import { faEllipsis } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, useCollapseNav } from '@pickleballinc/react-ui';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';

import { NAVIGATION_ITEMS } from '@/components/Header/nav.config';
import { BLOG_LABEL } from '@/utils/helpers/constants';

function isActiveMenuItem(href: string, pathname: string, baseOrigin: string) {
	if (process.env.NODE_ENV === 'production') {
		if (['https://pickleballtournaments.com', 'https://pickleballtournamentsdev.net'].includes(baseOrigin)) {
			return baseOrigin.startsWith(href);
		}

		if (['https://pickleballleagues.com', 'https://pickleballleaguesdev.net'].includes(baseOrigin)) {
			return baseOrigin.startsWith(href);
		}
	}

	return pathname.startsWith(href === '/news/all' ? '/news' : href);
}

interface MenuItemProps {
	children: React.ReactNode;
	href: string;
	baseOrigin: string;
}

const MenuItem = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement> & MenuItemProps>(function MenuItem(props, propsRef) {
	const { className, href, children, baseOrigin, ...rest } = props;
	const { pathname } = useRouter();

	const isBlog = children === BLOG_LABEL;

	if (isBlog) return null;

	const menuItemClasses = clsx(
		'relative flex font-semibold text-white transition-all duration-150 hover:text-opacity-60',
		{
			'before:content-[""] before:absolute before:inset-x-0 before:bottom-1 before:h-1 before:bg-white': isActiveMenuItem(
				href,
				pathname || '',
				baseOrigin
			)
		},
		className
	);

	return (
		<div className={menuItemClasses} {...rest} ref={propsRef}>
			<Link className="flex items-center px-2" href={href} target={props.target}>
				{children}
			</Link>
		</div>
	);
});

export const Nav = ({ baseOrigin }: { baseOrigin: string }) => {
	const { pathname } = useRouter();
	const containerRef = useRef<HTMLDivElement>(null);
	const { hidden, registerItem, ready } = useCollapseNav({
		containerRef
	});

	return (
		<div
			className={`relative hidden flex-1 gap-1 overflow-hidden text-sm text-white transition-opacity duration-200 sm:inline-flex ${
				ready ? 'opacity-100' : 'opacity-0'
			}`}
			ref={containerRef}
		>
			<ul className="flex items-center gap-1">
				{NAVIGATION_ITEMS.filter((item) => item.children !== BLOG_LABEL).map((item, index) => (
					<li key={index} className="h-[56px]">
						<MenuItem
							className={`${hidden.some((id) => id === item.id) ? 'select-none opacity-0' : ''} h-full`}
							data-collid={item.id}
							target={item.target}
							rel="noopener noreferrer"
							ref={(el) => registerItem(el, item.id, index)}
							href={item.href}
							baseOrigin={baseOrigin}
							data-testid={`nav-${item.children}`}
						>
							{item.children}
						</MenuItem>
					</li>
				))}
			</ul>
			{hidden.length > 0 && (
				<div className="sticky inset-y-0 right-0 ml-auto inline-flex px-6">
					<Menu showArrow={false} placement="bottom" offset={0}>
						<Menu.Trigger asChild>
							<div className="flex cursor-pointer items-center">
								<FontAwesomeIcon icon={faEllipsis} className="block text-xl" />
							</div>
						</Menu.Trigger>
						<Menu.List className="min-w-[160px] !rounded-t-none">
							{NAVIGATION_ITEMS.filter((item) => hidden.some((id) => id === item.id)).map((item) => {
								if (item.children === BLOG_LABEL) return null;

								return (
									<React.Fragment key={item.id}>
										<Menu.Item className="cursor-pointer px-2 py-1 text-sm font-normal hover:bg-none hover:underline">
											<Link
												href={item.href}
												target={item.target}
												className={`outline-0 ${pathname?.startsWith(item.href) ? 'underline' : ''}`}
											>
												{item.children}
											</Link>
										</Menu.Item>
									</React.Fragment>
								);
							})}
						</Menu.List>
					</Menu>
				</div>
			)}
		</div>
	);
};
