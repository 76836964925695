import { faFacebook, faInstagram, faTiktok, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Image from 'next/image';
import Link from 'next/link';

import getCDNURL from './utils/getCDNURL';
export interface FooterProps {
	isTourneyPage?: boolean;
	baseUrl: string;
	cdnUrl: string;
}

export const Footer = ({ isTourneyPage, baseUrl, cdnUrl }: FooterProps) => {
	const contactUsURL = isTourneyPage ? `https://contact.${baseUrl}/?module=tourney` : `https://contact.${baseUrl}`;

	return (
		<footer className="flex flex-col justify-center bg-white py-10 shadow-xs" id="pb-footer">
			<div className={`flex justify-center ${isTourneyPage ? 'pb-[34px]' : 'pb-10'}`}>
				{isTourneyPage ? (
					<Image
						src={`${getCDNURL(`${cdnUrl}/pickleballtournaments-app/logo-full.png`, 320, 84)}`}
						alt="Pickleballtournaments.com"
						width={160}
						height={42}
						className="pr-4"
					/>
				) : (
					<Image
						src={`${getCDNURL(`${cdnUrl}/pickleball-app/assets/images/logo-full.svg`, 160, 30)}`}
						alt="Pickleball.com"
						width={160}
						height={30}
					/>
				)}
			</div>
			<div className="mx-auto flex w-full max-w-7xl flex-col items-center gap-2 px-2 pt-4 sm:px-4 lg:hidden">
				<div className="flex flex-row gap-3 pb-3">
					<Link className="hover:!opacity-60" href="https://www.facebook.com/pickleballcom/" rel="noopener noreferrer" target="_blank">
						<FontAwesomeIcon icon={faFacebook} color="#000000" size="xl" />
					</Link>
					<Link className="hover:!opacity-60" href="https://twitter.com/pickleball_com" rel="noopener noreferrer" target="_blank">
						<FontAwesomeIcon icon={faXTwitter} color="#000000" size="xl" />
					</Link>
					<Link className="hover:!opacity-60" href="https://www.instagram.com/pickleballdotcom/" rel="noopener noreferrer" target="_blank">
						<FontAwesomeIcon icon={faInstagram} color="#000100" size="xl" />
					</Link>
					<Link
						className="hover:!opacity-60"
						href="https://www.tiktok.com/@pickleballdotcom?lang=en"
						rel="noopener noreferrer"
						target="_blank"
					>
						<FontAwesomeIcon icon={faTiktok} color="#000000" size="xl" />
					</Link>
				</div>
			</div>
			<div className="mx-auto flex w-full max-w-7xl flex-col items-center gap-2 border-t border-gray-200 px-2 pt-4 sm:px-4 lg:flex-row lg:gap-0">
				<div className="text-center text-sm text-gray-900">{`© ${moment().get('year')} Pickleball OpCo LLC, All Rights Reserved.`}</div>
				<div className="mx-auto hidden flex-col items-center gap-2 pl-16 pt-4 lg:flex">
					<ul className="flex flex-row gap-4 pb-3">
						<li>
							<Link
								className="hover:!opacity-60"
								href="https://www.facebook.com/pickleballcom/"
								rel="noopener noreferrer"
								target="_blank"
								aria-label="Pickleball Facebook"
							>
								<FontAwesomeIcon icon={faFacebook} color="#000000" size="2xl" />
							</Link>
						</li>
						<li>
							<Link
								className="hover:!opacity-60"
								href="https://twitter.com/pickleball_com"
								rel="noopener noreferrer"
								target="_blank"
								aria-label="Pickleball X"
							>
								<FontAwesomeIcon icon={faXTwitter} color="#000000" size="2xl" />
							</Link>
						</li>
						<li>
							<Link
								className="hover:!opacity-60"
								href="https://www.instagram.com/pickleballdotcom/"
								rel="noopener noreferrer"
								target="_blank"
								aria-label="Pickleball Instagram"
							>
								<FontAwesomeIcon icon={faInstagram} color="#000100" size="2xl" />
							</Link>
						</li>
						<li>
							<Link
								className="hover:!opacity-60"
								href="https://www.tiktok.com/@pickleballdotcom?lang=en"
								rel="noopener noreferrer"
								target="_blank"
								aria-label="Pickleball TikTok"
							>
								<FontAwesomeIcon icon={faTiktok} color="#000000" size="2xl" />
							</Link>
						</li>
					</ul>
				</div>
				<div className="flex items-center">
					<ul className="m-0 flex flex-wrap justify-center divide-gray-200 p-0">
						<li className="list-none border-r pr-2 text-sm">
							<Link href={contactUsURL} className="font-semibold leading-4 text-gray-900 hover:underline hover:underline-offset-2">
								Contact us
							</Link>
						</li>
						<li className="list-none border-r px-2 text-sm">
							<Link
								href={isTourneyPage ? `https://${baseUrl}/privacy-policy` : '/privacy-policy'}
								className="font-semibold leading-4 text-gray-900 hover:underline hover:underline-offset-2"
							>
								Privacy Policy
							</Link>
						</li>
						<li className="list-none border-r px-2 text-sm">
							<Link
								href={isTourneyPage ? `https://${baseUrl}/terms-of-use` : '/terms-of-use'}
								className="font-semibold leading-4 text-gray-900 hover:underline hover:underline-offset-2"
							>
								Terms of Use
							</Link>
						</li>
						<li className="list-none pl-2 text-sm">
							<Link
								href={isTourneyPage ? `https://${baseUrl}/dmca-notice` : '/dmca-notice'}
								className="font-semibold leading-4 text-gray-900 hover:underline hover:underline-offset-2"
							>
								DMCA Notice
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
};
