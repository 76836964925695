'use client';

import {
	faCalendar,
	faFaceFrown,
	faLayerGroup,
	faLongArrowRight,
	faSearch,
	faSitemap,
	faSpinnerThird,
	faTicketSimple,
	faUsers
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, InputField } from '@pickleballinc/react-ui';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import _debounce from 'lodash/debounce';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { GlobalSearchNewsInterface, SearchRes } from '@/app/api/v1/search/route';

type GlobalSearchButtonResponse = {
	DisplayOrder: string;
	IsEnabled: boolean;
	Title: string;
	URL: string | null;
};

export type GlobalSearchButtonsResponse = {
	payload: GlobalSearchButtonResponse[];
};

interface GlobalSearchProps {
	searchVisible: boolean;
	globalSearchButtons: GlobalSearchButtonsResponse;
	toggleRef?: React.MutableRefObject<any>;
	mainColor: string;
	onHide?: (visible: boolean) => void;
}

export default function GlobalSearch({ searchVisible, globalSearchButtons, toggleRef, onHide, mainColor }: GlobalSearchProps) {
	const [searchTerm, setSearchTerm] = useState('');
	const containerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleClickOutside = useCallback(
		(e: any) => {
			if (containerRef.current && !containerRef.current.contains(e.target) && !toggleRef?.current?.contains(e.target)) {
				if (onHide) {
					onHide(false);
				}
			}
		},
		[onHide, toggleRef]
	);

	const router = useRouter();

	useEffect(() => {
		router.events.on('routeChangeStart', () => {
			if (onHide) {
				onHide(true);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router]);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		if (searchVisible && inputRef.current) {
			inputRef.current.focus();
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [searchVisible, handleClickOutside]);

	const debouncedSearch = useRef(
		_debounce(async (value: string) => {
			setSearchTerm(value);
		}, 300)
	).current;

	async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		debouncedSearch(e.target.value);
	}

	const { data: globalSearchData, isFetching } = useQuery<any>(
		['getGlobalSearch', searchTerm],
		async () => {
			const response = await fetch(`/api/v1/search?query=${searchTerm}`);
			const result: { data: SearchRes; statusCode: number; error?: string } = await response.json();
			return result.data;
		},
		{
			enabled: searchTerm.length > 2,
			initialData: {
				payload: [],
				tourneys: []
			},
			refetchOnWindowFocus: false
		}
	);

	return (
		<div className="absolute top-full z-50 w-full bg-white p-4 shadow-md" ref={containerRef}>
			<div className="mx-auto max-w-[1200px] px-2">
				<div className="relative">
					<InputField
						type="text"
						className={classNames(
							'w-full !rounded-none !border-0 !border-b  !shadow-[0_0_0_0_transparent] !transition-none focus:!outline-none',
							'!border-' + mainColor
						)}
						placeholder="Where to go?"
						autoFocus
						onChange={(e) => handleChange(e)}
						ref={inputRef}
						inputProps={{
							className: '!border-0 !rounded-none focus:!border-0 shadow-none focus:!outline-none'
						}}
					/>
					<div className="absolute inset-y-0 right-0 flex items-center px-2">
						<FontAwesomeIcon icon={faSearch} size="sm" />
					</div>
				</div>
				<div className="mt-4 text-sm font-medium text-gray-800">Suggested searches</div>
				<div className="mt-1 flex flex-wrap items-center gap-1 bg-white pb-2">
					{globalSearchButtons?.payload
						.filter((item) => item.IsEnabled)
						.map((button) => {
							return (
								<Link
									href={`${button.URL}`}
									key={button.Title}
									className="cursor-pointer rounded-full bg-black px-3 py-1 text-xs font-medium text-white hover:bg-black/80"
									target="_blank"
								>
									{button.Title}
								</Link>
							);
						})}
				</div>

				{isFetching ? (
					<div className="flex items-center justify-center py-6 text-sm text-gray-600">
						<div className="block">
							<FontAwesomeIcon icon={faSpinnerThird} spin />
						</div>
						<span className="ml-2">Please wait...</span>
					</div>
				) : searchTerm.length > 2 && globalSearchData ? (
					<DisplayResults mainColor={mainColor} data={globalSearchData || { payload: [], tourneys: [] }} searchTerm={searchTerm} />
				) : null}
			</div>
		</div>
	);
}

interface DisplayResultsProps {
	data: any;
	mainColor: string;
	searchTerm: string;
}

const DisplayResults = ({ data, mainColor, searchTerm }: DisplayResultsProps) => {
	if (data.payload?.length === 0 && data.tourneys?.length === 0 && data.players?.length === 0 && data.news.length === 0) {
		return (
			<div className="mt-4 flex items-center justify-center py-4 text-sm text-gray-400">
				<FontAwesomeIcon icon={faFaceFrown} className="mr-2 block text-lg" />
				No results found. Try refining your search.
			</div>
		);
	}

	const pickleballBaseURL = process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME ? `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}` : '';
	const tournamentsBaseURL = process.env.NEXT_PUBLIC_PT_URL || '';

	return (
		<div className="global-search-scroll max-h-80 overflow-y-auto py-4 pl-4 text-black">
			{data.news.length > 0 && (
				<>
					<div className="after:content[''] relative mb-1 flex w-full items-center bg-white text-xs font-medium uppercase tracking-wider opacity-60 after:absolute after:top-3 after:-z-10 after:h-px after:w-full">
						<span className="relative bg-white font-bold">News</span>
						<span className="ml-4">|</span>
						<Link
							href={`${pickleballBaseURL}/news/all`}
							className={classNames(
								'relative ml-4 flex items-center bg-white underline underline-offset-4 font-bold',
								'text-' + mainColor,
								'decoration-' + mainColor
							)}
							target="_blank"
						>
							See all
							<FontAwesomeIcon icon={faLongArrowRight} className="ml-1" />
						</Link>
					</div>
					{data.news.map((newsArticle: GlobalSearchNewsInterface) => (
						<div className="m-0" key={newsArticle.slug}>
							<Link
								href={`${pickleballBaseURL}/${newsArticle.categorySlug}/${newsArticle.slug}`}
								className="flex items-center py-1 text-sm hover:bg-gray-200"
							>
								{newsArticle.imageUrl ? (
									<Image
										width={64}
										height={25.6}
										className="mr-2 border border-experiment"
										src={newsArticle.imageUrl}
										alt={newsArticle.imageAltText}
									/>
								) : (
									<div className="icon mr-2 flex size-8 items-center justify-center rounded-full border border-experiment">
										<FontAwesomeIcon icon={faLayerGroup} className="text-experiment" />
									</div>
								)}
								<div className="ml-2">
									<p className="line-clamp-1">{newsArticle.title}</p>
									<div className="flex items-center">
										<FontAwesomeIcon icon={faCalendar} className="relative mr-1 mt-[-3px] block text-sm opacity-70" />
										<span className="mr-4 flex items-center text-xs uppercase opacity-60">
											{momentTimezone
												.utc(newsArticle.publishDateDisplayed)
												.tz('America/New_York')
												.format('MMM DD, YYYY hh:mm A [ET]')}
										</span>
									</div>
									<span className="block text-sm opacity-60">{newsArticle.authorFullName}</span>
								</div>
							</Link>
						</div>
					))}
				</>
			)}
			{data.tourneys.length > 0 && (
				<>
					<div className="after:content[''] relative mb-1 mt-4 flex w-full items-center bg-white text-xs font-medium uppercase tracking-wider opacity-60 after:absolute after:top-3 after:-z-10 after:h-px after:w-full">
						<span className="relative bg-white font-bold">Tournaments</span>
						<span className="ml-4">|</span>
						<Link
							href={`${tournamentsBaseURL}/search${searchTerm ? `?keyword=${searchTerm}` : ''}`}
							className={classNames(
								'relative ml-4 flex items-center bg-white underline underline-offset-4 font-bold',
								'text-' + mainColor,
								'decoration-' + mainColor
							)}
							target="_blank"
						>
							See all
							<FontAwesomeIcon icon={faLongArrowRight} className="ml-1" />
						</Link>
					</div>
					{data.tourneys.map((tourney: any) => {
						return (
							<div className="m-0 pr-2" key={tourney.TournamentID}>
								<Link
									href={`${tournamentsBaseURL}/tournaments/${tourney.slug || tourney.TournamentID}`}
									className="flex items-center py-1 text-sm hover:bg-gray-200"
									target="_blank"
								>
									<div
										className={classNames(
											'icon mr-2 flex h-8 w-8 shrink-0 items-center justify-center rounded-full border',
											'border-' + mainColor
										)}
									>
										<FontAwesomeIcon
											icon={faSitemap}
											className={classNames('relative -left-[1px] -rotate-90', 'text-' + mainColor)}
										/>
									</div>
									<div className="ml-2">
										<span className="line-clamp-2">{tourney.Title}</span>
										<div className="flex items-center">
											<FontAwesomeIcon icon={faTicketSimple} className="mr-1 block text-sm opacity-70" />
											<span className="mr-4 flex items-center text-xs uppercase opacity-60">
												{`${moment(tourney.RegistrationDateOpen).format('MMM DD')} - ${moment(tourney.RegistrationDateClosed).format('MMM DD')}`}
											</span>
											<FontAwesomeIcon icon={faCalendar} className="relative mr-1 mt-[-3px] block text-sm opacity-70" />
											<span className="flex items-center text-xs uppercase opacity-60">
												{`${moment(tourney.TourneyFromDate).format('MMM DD')} - ${moment(tourney.TourneyToDate).format('MMM DD')}`}
											</span>
										</div>
										<div className="block text-sm opacity-60">
											{`${tourney.LocationCity ? `${tourney.LocationCity}, ` : ''}${tourney.LocationState} - ${tourney.LocationCountry}`}
										</div>
									</div>
								</Link>
							</div>
						);
					})}
				</>
			)}
			{data.payload.filter((item: any) => item.ResultType === 'League').length > 0 && (
				<>
					<div className="after:content[''] relative mb-1 mt-4 flex w-full items-center bg-white text-xs font-medium uppercase tracking-wider opacity-60 after:absolute after:top-3 after:-z-10 after:h-px after:w-full">
						<span className="relative bg-white font-bold">Leagues</span>
						<span className="ml-4">|</span>
						<Link
							href={`${process.env.NEXT_PUBLIC_PL_URL}/leagues${searchTerm ? `?keyword=${searchTerm}` : ''}`}
							className={classNames(
								'relative ml-4 flex items-center bg-white underline underline-offset-4 font-bold',
								'text-' + mainColor,
								'decoration-' + mainColor
							)}
							target="_blank"
						>
							See all
							<FontAwesomeIcon icon={faLongArrowRight} className="ml-1" />
						</Link>
					</div>
					{data.payload
						.filter((item: any) => item.ResultType === 'League')
						.map((item: any) => {
							return (
								<div className="m-0" key={item.ID}>
									<Link
										href={`${process.env.NEXT_PUBLIC_PL_URL}/leagues/${item.ID}`}
										className="flex items-center py-1 text-sm hover:bg-gray-200"
									>
										<div
											className={classNames(
												'icon mr-2 flex h-8 w-8 items-center justify-center rounded-full border',
												'border-' + mainColor
											)}
										>
											<FontAwesomeIcon icon={faLayerGroup} className={classNames('text-' + mainColor)} />
										</div>
										<div className="ml-2">
											{item.Title}
											{item.SubTitle2 && (
												<div className="flex items-center">
													<FontAwesomeIcon icon={faCalendar} className="relative mr-1 mt-[-3px] block text-sm opacity-70" />
													<span className="mr-4 flex items-center text-xs uppercase opacity-60">{item.SubTitle2}</span>
												</div>
											)}
											<span className="block text-sm opacity-60">{item.SubTitle}</span>
										</div>
									</Link>
								</div>
							);
						})}
				</>
			)}
			{data.payload.filter((item: any) => item.ResultType === 'Club').length > 0 && (
				<>
					<div className="after:content[''] relative mb-1 mt-4 flex w-full items-center bg-white text-xs font-medium uppercase tracking-wider opacity-60 after:absolute after:top-3 after:-z-10 after:h-px after:w-full">
						<span className="relative bg-white font-bold">Clubs</span>
						<span className="ml-4">|</span>
						<Link
							href={`${pickleballBaseURL}/clubs${searchTerm ? `?keyword=${searchTerm}` : ''}`}
							className={classNames(
								'relative ml-4 flex items-center bg-white underline underline-offset-4 font-bold',
								'text-' + mainColor,
								'decoration-' + mainColor
							)}
							target="_blank"
						>
							See all
							<FontAwesomeIcon icon={faLongArrowRight} className="ml-1" />
						</Link>
					</div>
					{data.payload
						.filter((item: any) => item.ResultType === 'Club')
						.map((item: any) => {
							return (
								<div className="m-0" key={item.ID}>
									<Link href={`${pickleballBaseURL}/clubs/${item.ID}`} className="flex items-center py-1 text-sm hover:bg-gray-200">
										<div
											className={classNames(
												'icon mr-2 flex h-8 w-8 items-center justify-center rounded-full border',
												'border-' + mainColor
											)}
										>
											<FontAwesomeIcon icon={faUsers} className={classNames('text-' + mainColor)} />
										</div>
										<div className="ml-2">
											{item.Title}
											{item.SubTitle2 && (
												<div className="flex items-center">
													<span className="mr-4 flex items-center text-xs uppercase opacity-60">{item.SubTitle2}</span>
												</div>
											)}
											<span className="block text-sm opacity-60">{item.SubTitle}</span>
										</div>
									</Link>
								</div>
							);
						})}
				</>
			)}
			{data.players.length > 0 && (
				<>
					<div className="after:content[''] relative mb-1 mt-4 flex w-full items-center bg-white text-xs font-medium uppercase tracking-wider opacity-60 after:absolute after:top-3 after:-z-10 after:h-px after:w-full">
						<span className="relative bg-white font-bold">Players</span>
						<span className="ml-4">|</span>
						<Link
							href={`${pickleballBaseURL}/players${searchTerm ? `?search=${searchTerm}` : ''}`}
							className="ml-4 flex items-center bg-white font-bold text-experiment underline decoration-experiment underline-offset-4"
						>
							See all
							<FontAwesomeIcon icon={faLongArrowRight} className="ml-1" />
						</Link>
					</div>
					{data.players.map((item: any) => {
						return (
							<div className="m-0" key={item.ID}>
								<Link
									href={`${pickleballBaseURL}/players/${item.slug || item.ID}`}
									className="flex items-center py-1 text-sm hover:bg-gray-200"
								>
									<div className="icon mr-2 flex size-8 items-center justify-center rounded-full border border-experiment">
										<Avatar size="sm" imageUrl={item.Image} />
									</div>
									<div className="ml-2">
										{item.Title}
										<span className="block text-sm opacity-60">{item.SubTitle}</span>
									</div>
								</Link>
							</div>
						);
					})}
				</>
			)}
		</div>
	);
};
